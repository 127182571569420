import { getCookie, setCookie } from '../misc/cookie';

document.addEventListener('DOMContentLoaded', () => {
  const
    jsData = document.querySelector('.js-redirect'),
    userFavouriteLocale = jsData.dataset.userFavouriteLocale,
    cookieName = 'no_redirect_' + userFavouriteLocale
  ;

  // Pas de cookie, on peut se poser la question de la redirection
  if (null === getCookie(cookieName)) {
    // On crée le cookie pour ne plus traiter cette locale à l'avenir
    setCookie(cookieName, true, 365);

    const
      hostLocaleMapping = JSON.parse(jsData.dataset.hostLocaleMapping),
      bestDomain = getBestDomainForLocaleCode(userFavouriteLocale, hostLocaleMapping)
    ;

    if (window.location.hostname !== bestDomain) {
      let alternativeUrl = getAlternativeUrl(userFavouriteLocale);

      if (null === alternativeUrl) {
        alternativeUrl = `https://${bestDomain}`;
      }

      fetch(jsData.dataset.ajaxRoute, {
        method: 'POST',
        body: JSON.stringify({
          alternativeUrl: alternativeUrl,
          locale: getInternalLocaleCode(userFavouriteLocale),
        }),
        headers: new Headers({
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        }),
      })
        .then(response =>
          response.text().then(text => ({
            status: response.status,
            body: text,
          })),
        )
        .then(({ status, body }) => {
          if (200 === status) {
            const modalRedirect = document.querySelector('.js-modal-redirect');

            document.querySelector('.js-modal-redirect').innerHTML = body;
            document.querySelector('.js-modal-redirect').classList.add('active');

            closeModal(modalRedirect);
          }
        });
    }
  }
});

/**
 * TODO : rajouter les locales au fur et à mesure des livraisons
 * Fait le lien entre le code locale fourni par le navigateur
 * et ceux qu'on utilise dans notre mapping de domaines
 */
const getBestDomainForLocaleCode = (locale, mapping) => {
  if (['fr', 'fr_FR'].includes(locale)) {
    return 'www.soignon.fr';
  }

  return mapping[getInternalLocaleCode(locale)];
};

const getInternalLocaleCode = locale => {
  // if ('en_US' === locale) {
  //   return locale;
  // }

  if (['de', 'de_DE'].includes(locale)) {
    return 'de_DE';
  }

  if (['it', 'it_IT'].includes(locale)) {
    return 'it_IT';
  }

  return 'en_WWW';
};

/**
 * Retourne, si possible, l'URL alternative de la
 * page en fonction de la locale de l'utilisateur,
 * ou null si pas d'alternative
 */
const getAlternativeUrl = locale => {
  let alternatives = {};

  document.querySelectorAll('[hreflang]').forEach(tag => {
    alternatives[tag.hreflang] = tag.href;
  });

  console.log(alternatives);

  // Si anglais (US) et version US
  if ('en_US' === locale && alternatives.hasOwnProperty('en-us')) {
    return alternatives['en-us'];
  }

  // Si allemand (Allemagne) et version allemande
  if (['de', 'de_DE'].includes(locale) && alternatives.hasOwnProperty('de')) {
    return alternatives['de'];
  }

  // Si italien et version italienne
  if (['it', 'it_IT'].includes(locale) && alternatives.hasOwnProperty('it')) {
    return alternatives['it'];
  }

  // Si toute autre locale hors français (France) et qu'on a une version internationale
  if (!['fr', 'fr_FR'].includes(locale) && alternatives.hasOwnProperty('en')) {
    return alternatives['en'];
  }

  return null;
};

function closeModal(modalRedirect) {
  const buttonsClose = document.querySelectorAll('.js-modal-redirect__close');

  buttonsClose.forEach(buttonClose => {
    buttonClose.addEventListener('click', () => {
      modalRedirect.classList.remove('active');
    });
  });
}
