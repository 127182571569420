export function setCookie(name, value, days) {
  let expires = '';

  if (days) {
    let date = new Date();

    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = '; expires=' + date.toUTCString();
  }

  document.cookie = name + '=' + (value || '') + expires + '; path=/; sameSite=lax';
}

export function getCookie(name, defaultValue = null) {
  const
    decodedCookie = decodeURIComponent(document.cookie),
    ca = decodedCookie.split(';');

  name = name + '=';

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (' ' === c.charAt(0)) {
      c = c.substring(1);
    }

    if (0 === c.indexOf(name)) {
      return c.substring(name.length, c.length);
    }
  }

  return defaultValue;
}

export function removeCookie(name) {
  let
    expires = '',
    date = new Date();

  date.setTime(0);
  expires = '; expires=' + date.toUTCString();

  document.cookie = name + '=' + expires + '; path=/';
}
